<template>
	<div class="container">
		<div class="d-flex justify-content-center py-5" v-if="loading">
			<div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<template v-else>
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item active">
						<span v-text="`${project.Code} - ${project.Name}`"></span>
					</li>

					<li class="breadcrumb-item active">
						Configurations
					</li>
				</ol>
			</nav>

			<table class="table">
				<thead>
				<tr>
					<th>Name</th>
					<th>Last update</th>
					<th>Version</th>
					<th></th>
				</tr>
				</thead>
				<tr v-for="(config, index) in configurations">
					<td class="align-middle" v-text="config.Name"></td>
					<td class="align-middle" v-rel-date="config.UpdatedAt"></td>
					<td class="align-middle" v-text="config.Version"></td>
					<td class="align-middle">
						<router-link :to="{name: 'projects-editor-uuid-cuuid-settings', params: {
							uuid: project.Uuid,
							cuuid: config.Uuid
						}}" class="mx-1 btn btn-outline-dark rounded" title="Settings" v-if="canRead('projects_config')">
							<i class="fa fa-sliders-h"></i>
						</router-link>
						<router-link :to="{name: 'projects-editor-uuid-cuuid-config-index', params: {
							uuid: project.Uuid,
							cuuid: config.Uuid
						}}" class="mx-1 btn btn-outline-primary rounded" title="Edit configuration" v-if="canRead('projects_config')">
							<i class="fa fa-cog"></i>
						</router-link>
						<a :href="`data:text/json;charset=utf-8,${JSON.stringify(config.Configuration)}`"
						   :download="`${project.Code.toLowerCase()}-${config.Version}.json`"
						   class="mx-1 btn btn-outline-accent-2 rounded" title="Download .json"
						   v-if="canRead('projects_config')">
							<i class="fa fa-download"></i>
						</a>
						<router-link :to="{name: 'projects-editor-uuid-cuuid-tests-index', params: {
							uuid: project.Uuid,
							cuuid: config.Uuid
						}}" class="mx-1 btn btn-outline-success rounded" title="Spinners" v-if="canRead('spinners')">
							<i class="fa fa-vials"></i>
						</router-link>
						<router-link :to="{name: 'projects-editor-uuid-cuuid-tests-reports-report_id', params: {
							uuid: project.Uuid,
							cuuid: config.Uuid
						}}" class="mx-1 btn btn-outline-info rounded" title="Spinners" v-if="canRead('spinners')">
							<i class="fa fa-flag-checkered"></i>
						</router-link>
						<router-link :to="{name: 'projects-editor-uuid-cuuid-localizations-index', params: {
							uuid: project.Uuid,
							cuuid: config.Uuid
						}}" class="mx-1 btn btn-outline-accent rounded" title="Localizations" v-if="canRead('localization')">
							<i class="fa fa-language"></i>
						</router-link>
						<button class="mx-1 btn btn-danger rounded" @click="deleteConfiguration(index)"
								title="Delete" v-if="canWrite('projects_config')">
							<i class="fa fa-trash-alt"></i>
						</button>
					</td>
				</tr>
			</table>

			<router-link class="btn btn-primary px-3 mt-2"
						 :to="{name: 'projects-editor-uuid-config-new', params: {uuid: $route.params.uuid}}">
				Create a new configuration
			</router-link>

		</template>
	</div>
</template>

<script>
	import api from '@/api/'
	import messageBox from '@/components/modals/confirm'

	export default {
		data() {
			return {
				configurations: []
			}
		},
		methods: {
			async deleteConfiguration(index) {
				let config = this.configurations[index]

				messageBox({
					message: `Want to delete configuration ${config.Name}? Are you sure?`
				}).then(
						async () => {
							await api.Projects.deleteConfiguration(config.Uuid)
							this.configurations.splice(index, 1)
						}
				)

			}
		},
		computed: {
			loading() {
				return this.$asyncComputed.project.updating || this.$asyncComputed.configurations.updating
			}
		},
		asyncComputed: {
			async project() {
				if (this.$route.params.uuid) {
					return await api.Projects.getProject(this.$route.params.uuid)
				}
			},
			async configurations() {
				if (this.$route.params.uuid) {
					let config = await api.Projects.getProjectConfigurations(this.$route.params.uuid)
					this.$store.commit('SlotConfigurator/currentSlot', config.Configuration)
					return config
				}
			}
		}
	}
</script>
