var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._m(0)]):[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Configurations ")])])]),_c('table',{staticClass:"table"},[_vm._m(1),_vm._l((_vm.configurations),function(config,index){return _c('tr',[_c('td',{staticClass:"align-middle",domProps:{"textContent":_vm._s(config.Name)}}),_c('td',{directives:[{name:"rel-date",rawName:"v-rel-date",value:(config.UpdatedAt),expression:"config.UpdatedAt"}],staticClass:"align-middle"}),_c('td',{staticClass:"align-middle",domProps:{"textContent":_vm._s(config.Version)}}),_c('td',{staticClass:"align-middle"},[(_vm.canRead('projects_config'))?_c('router-link',{staticClass:"mx-1 btn btn-outline-dark rounded",attrs:{"to":{name: 'projects-editor-uuid-cuuid-settings', params: {
						uuid: _vm.project.Uuid,
						cuuid: config.Uuid
					}},"title":"Settings"}},[_c('i',{staticClass:"fa fa-sliders-h"})]):_vm._e(),(_vm.canRead('projects_config'))?_c('router-link',{staticClass:"mx-1 btn btn-outline-primary rounded",attrs:{"to":{name: 'projects-editor-uuid-cuuid-config-index', params: {
						uuid: _vm.project.Uuid,
						cuuid: config.Uuid
					}},"title":"Edit configuration"}},[_c('i',{staticClass:"fa fa-cog"})]):_vm._e(),(_vm.canRead('projects_config'))?_c('a',{staticClass:"mx-1 btn btn-outline-accent-2 rounded",attrs:{"href":`data:text/json;charset=utf-8,${JSON.stringify(config.Configuration)}`,"download":`${_vm.project.Code.toLowerCase()}-${config.Version}.json`,"title":"Download .json"}},[_c('i',{staticClass:"fa fa-download"})]):_vm._e(),(_vm.canRead('spinners'))?_c('router-link',{staticClass:"mx-1 btn btn-outline-success rounded",attrs:{"to":{name: 'projects-editor-uuid-cuuid-tests-index', params: {
						uuid: _vm.project.Uuid,
						cuuid: config.Uuid
					}},"title":"Spinners"}},[_c('i',{staticClass:"fa fa-vials"})]):_vm._e(),(_vm.canRead('spinners'))?_c('router-link',{staticClass:"mx-1 btn btn-outline-info rounded",attrs:{"to":{name: 'projects-editor-uuid-cuuid-tests-reports-report_id', params: {
						uuid: _vm.project.Uuid,
						cuuid: config.Uuid
					}},"title":"Spinners"}},[_c('i',{staticClass:"fa fa-flag-checkered"})]):_vm._e(),(_vm.canRead('localization'))?_c('router-link',{staticClass:"mx-1 btn btn-outline-accent rounded",attrs:{"to":{name: 'projects-editor-uuid-cuuid-localizations-index', params: {
						uuid: _vm.project.Uuid,
						cuuid: config.Uuid
					}},"title":"Localizations"}},[_c('i',{staticClass:"fa fa-language"})]):_vm._e(),(_vm.canWrite('projects_config'))?_c('button',{staticClass:"mx-1 btn btn-danger rounded",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteConfiguration(index)}}},[_c('i',{staticClass:"fa fa-trash-alt"})]):_vm._e()],1)])})],2),_c('router-link',{staticClass:"btn btn-primary px-3 mt-2",attrs:{"to":{name: 'projects-editor-uuid-config-new', params: {uuid: _vm.$route.params.uuid}}}},[_vm._v(" Create a new configuration ")])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Last update")]),_c('th',[_vm._v("Version")]),_c('th')])])
}]

export { render, staticRenderFns }